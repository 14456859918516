import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'

const Contact = ({ data }) => {
  return (
    <Layout>
      <SEO title="Om" description="Om AN Takplåt" />
      <Container>
        <PageTitle>Om AN takplåt</PageTitle>
        Information om AN Takplåt AB.
      </Container>
    </Layout>
  )
}

export default Contact
